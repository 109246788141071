<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M14 18.175V12q0-.425.288-.713Q14.575 11 15 11t.713.287Q16 11.575 16 12v6.175l1.875-1.875q.275-.275.687-.288q.413-.012.713.288q.3.275.313.687q.012.413-.288.713l-3.6 3.6q-.15.15-.325.212q-.175.063-.375.063t-.375-.063q-.175-.062-.325-.212l-3.6-3.6q-.275-.275-.275-.687q0-.413.3-.713q.3-.275.7-.288q.4-.012.7.288ZM8 5.825L6.125 7.7Q5.8 8.025 5.4 8q-.4-.025-.675-.3q-.3-.3-.3-.713q0-.412.275-.687l3.6-3.6q.15-.15.325-.213q.175-.062.375-.062t.375.062q.175.063.325.213l3.6 3.6q.275.275.275.7q0 .425-.275.7q-.3.3-.712.3q-.413 0-.713-.3L10 5.825V12q0 .425-.287.712Q9.425 13 9 13t-.712-.288Q8 12.425 8 12Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MaterialSymbolsSwapVertRounded",
};
</script>
